import React from 'react'
import { Switch, Route } from 'react-router'
import { MainPage } from './ui/pages/MainPage'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route>
          <MainPage />
        </Route>
      </Switch>
    </div>
  )
}

export default App
