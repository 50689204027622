import firstSectionClock from '../../ui/assets/images/Clock.svg'
import firstSectionLab from '../../ui/assets/images/Lab.svg'
import firstSectionResultsOnline from '../../ui/assets/images/Results_Online.svg'
import firstSectionVirtualGPScreen from '../../ui/assets/images/Virtual_GP_Screen.svg'
import step1 from '../../ui/assets/images/step1.svg'
import step2 from '../../ui/assets/images/step2.svg'
import step3 from '../../ui/assets/images/step3.svg'
import step4 from '../../ui/assets/images/step4.svg'
import step5 from '../../ui/assets/images/step5.svg'
import arrowTop from '../../ui/assets/images/arrowTop.svg'
import arrowBottom from '../../ui/assets/images/arrowBottom.svg'

export const firstSectionData = [
  {
    imgSource: firstSectionLab,
    imgAlt: 'lab',
    title: 'Fast, accurate results',
    subtitle: 'A highly accurate test with results in 3-5 days',
  },
  {
    imgSource: firstSectionClock,
    imgAlt: 'Clock',
    title: 'Viable 2 weeks after symptoms',
    subtitle: 'Valid results as soon as 14 days after the onset of symptoms',
  },
  {
    imgSource: firstSectionResultsOnline,
    imgAlt: 'results online',
    title: 'Results online',
    subtitle:
      'Get your results online through the Testing For All online portal',
  },
  {
    imgSource: firstSectionVirtualGPScreen,
    imgAlt: 'virtual gp screen',
    title: '24/7 virtual GP appointments',
    subtitle:
      'As part of your existing benefit, 24/7 virtual GP appointments are available to give you ongoing advice and support',
  },
]

export const howItWorksSectionData = [
  {
    imgSrc: step1,
    imgAlt: 'step1',
    title: 'Step 1',
    subtitle:
      'Click the link below and register online with Testing For All. Enter your coupon code at the checkout.',
    arrowBottomSrc: arrowBottom,
  },
  {
    imgSrc: step2,
    imgAlt: 'step2',
    title: 'Step 2',
    subtitle:
      'Your kit will be posted within 1-2 working days. It contains a self-administered finger-prick blood sample.',
    arrowTopSrc: arrowTop,
  },
  {
    imgSrc: step3,
    imgAlt: 'step3',
    title: 'Step 3',
    subtitle:
      'Send your test back on the day that you have taken it, using our prepaid mailer',
    arrowBottomSrc: arrowBottom,
  },
  {
    imgSrc: step4,
    imgAlt: 'step4',
    title: 'Step 4',
    subtitle:
      'Once your sample has arrived at the lab, it takes 2-3 working days to be processed',
    arrowTopSrc: arrowTop,
  },
  {
    imgSrc: step5,
    imgAlt: 'step5',
    title: 'Step 5',
    subtitle:
      'Once the lab has processed your sample, results become available on our online portal',
  },
]