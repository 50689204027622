import React from 'react'
import clsx from 'clsx'
import healthshieldLogo from '../assets/images/healthshieldlogo-2.svg'
import {
  firstSectionData,
  howItWorksSectionData,
} from '../../app/main/constants'

export const MainPage = () => {
  return (
    <div className="healthshield-page">
      <header>
        <div className="header-logo-wrapper">
          <img src={healthshieldLogo} alt="Healthshild Logo" />
        </div>
      </header>
      <main>
        <section className="title-content-wrapper">
          <div className="title-content-background-wrapper">
            <div className="title-content-text">
              <div className="inner-content">
                <div className="title-content-text-wrapper">
                  <div className="title-content-text-main-title flex flex-column">
                    <span>Order a Coronavirus antibody test</span>
                  </div>
                  <div className="title-content-text-main-subtitle flex flex-column">
                    <span>
                      Fast and accurate tests with results in 3-5 days
                    </span>
                    <span>at a discounted rate of only £35</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="title-content-background-first"></div>
            <div className="title-content-background-second"></div>
            <div className="title-content-background-image"></div>
          </div>
        </section>
        <section className="options-content-wrapper">
          <div className="inner-content">
            <div className="options-title">
              <span>
                Order a clinically validated, CE marked antibody test today and
                find out if you have been exposed to COVID-19 for only £35.
                Fulfilled by our trusted partner, Testing For All. The test is
                easy to self-administer from home and requires 0.5ml finger
                prick blood sample, which is approximately 10-15 drops.
              </span>
            </div>
            <div className="options-items-wrapper flex flex-column">
              <div className="options-items">
                <ul className="options-content-list flex">
                  {firstSectionData.map((item) => (
                    <li
                      className={clsx(
                        'options-content-list-item flex flex-column',
                        item.imgAlt === 'results online'
                          ? 'results-online'
                          : '',
                      )}
                    >
                      <div className="options-content-list-item-image-wrapper">
                        <img
                          src={item.imgSource}
                          alt={item.imgAlt}
                          className="options-content-list-item-image"
                        />
                        <div className="options-content-list-item-image-shadow"></div>
                      </div>
                      <span className="options-content-list-item-title">
                        {item.title}
                      </span>
                      <span className="options-content-list-item-subtitle">
                        {item.subtitle}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="options-coupon-code-wrapper flex flex-center flex-column">
                <div className="options-coupon-code-title">
                  <span>
                    Enter coupon code ‘Lorem Ipsum’ at the checkout to save £4
                  </span>
                </div>
                <div className="options-coupon-code-links-wrapper">
                  <div className="options-coupon-code-links flex">
                    <div className="options-coupon-code-links-first">
                      <a
                        href="https://www.testingforall.org/product/covid-19-home-antibody-test/"
                        className="options-coupon-code-link border-gradient"
                        target="_blank"
                      >
                        Order an antibody test{' '}
                        <div className="arrow-right"></div>
                      </a>
                      <span className="options-coupon-code-link-subtitle">
                        You will be taken to our partner site to register your
                        details
                      </span>
                    </div>
                    <div>
                      <a
                        href="https://www.testingforall.org/individuals/"
                        className="options-coupon-code-link border-gradient"
                        target="_blank"
                      >
                        Have a question? Find out how it works{' '}
                        <div className="arrow-right"></div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="options-coupon-code-links-terms">
                  <span>
                    <a
                      href="https://www.testingforall.org/terms-of-service/"
                      className="options-coupon-code-terms-link"
                      target="_blank"
                    >
                      Terms and conditions apply
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-works-content-wrapper">
          <div className="inner-content">
            <div className="how-it-works-titles">
              <div className="how-it-works-title">
                <span>How it works</span>
              </div>
              <div className="how-it-works-subtitle">
                <span>Fast and accurate tests from home</span>
              </div>
            </div>
            <div className="how-it-works-items">
              <ul className="how-it-works-list flex">
                {howItWorksSectionData.map((item) => (
                  <li className="how-it-works-item">
                    <div className="how-it-works-item-img-wrapper">
                      <img
                        src={item.imgSrc}
                        alt={item.imgAlt}
                        className="how-it-works-item-img"
                        style={{
                          marginTop: item.imgAlt === 'step3' ? -38 : 0,
                          height: item.imgAlt === 'step2' ? 154 : 'auto',
                        }}
                      />
                      {item.arrowBottomSrc || item.arrowTopSrc ? (
                        <div
                          style={{
                            background: `url(${
                              item.arrowBottomSrc || item.arrowTopSrc
                            }) no-repeat center center`,
                          }}
                          className={clsx(
                            `how-it-works-item-img-arrow ${item.imgAlt}`,
                            item.arrowBottomSrc ? 'arrow-bottom' : 'arrow-top',
                          )}
                        ></div>
                      ) : null}
                    </div>
                    <span className="how-it-works-item-title">
                      {item.title}
                    </span>
                    <span className="how-it-works-item-subtitle">
                      {item.subtitle}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="how-it-works-links flex">
              <div className="how-it-works-links-wrapper flex-column flex-center">
                <a
                  href="https://www.testingforall.org/product/covid-19-home-antibody-test/"
                  className="how-it-works-link"
                  target="_blank"
                >
                  Order an antibody test <div className="arrow-right"></div>
                </a>
                <span className="how-it-works-link-subtitle">
                  You will be taken to our partner site to register your details
                </span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
